import React from "react";

import { AppLink } from "@base";
import PaintStrokeIcon from "@svg/paintStroke.svg";
import PaintStrokeSmallIcon from "@svg/paintStrokeSmall.svg";
import FlameIcon from "@svg/flame.svg";
import { useAppState } from "@state";
import getContrast from "@utils/getContrast";
import brand from "@utils/brand";
import classNames from "classnames";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  outline,
  icon,
  className: _className,
}) => {
  const [{ ec }] = useAppState();
  const { lakota, lgca, romero } = brand;

  let classes = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 group leading-tighter transition`;

  if (romero) {
    classes += ` hover:text-white font-medium duration-200`;
  }

  if (!romero) {
    classes += ` uppercase font-bold duration-200`;
  }

  if (color === "white") {
    classes += ` text-red`;
  } else if (romero && outline) {
    classes += ` text-blue`;
  } else {
    classes += ` text-white`;
  }

  if (romero && !outline) {
    classes += ` bg-${color}`;
  }

  if (lgca) {
    classes += ` bg-${color} font-extended tracking-1 transform transition hover:scale-105 group-hover:scale-105`;
  }

  if (ec?.colors?.accent && getContrast(ec?.colors.accent, [255, 255, 255])) {
    classes += " text-black";
  } else if (romero && color === "white") {
    classes += " text-blue";
  } else if ((romero && color === "blue") || (!romero && color !== "white")) {
    classes += " text-white";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (outline) {
    classes += ` border-2 border-${color} text-${color} hover:bg-${color}`;
  }

  if (romero && !outline) {
    classes += ` hover:bg-gold`;
  }

  // set rounded corners
  switch (rounded) {
    case "xl":
      classes += " rounded-xl";
      break;
    case true:
      classes += " rounded-full";
      break;
    default:
      break;
  }

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " p-2.5 text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " px-4 py-3 text-xs";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    case "sm":
      classes += " py-3 px-6 text-xs";
      break;
    default:
      classes += " py-4 px-10 text-sm";
      break;
  }

  // todo: refactor this element
  // eslint-disable-next-line react/no-unstable-nested-components
  const PaintStroke = React.memo(({ className: _classname }) => {
    if (size === "xxs" || size === "md") {
      return <PaintStrokeSmallIcon className={_classname} />;
    }
    return <PaintStrokeIcon className={_classname} />;
  });

  if (to) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={`${classes}`}
        onClick={onClick}
        style={
          ec?.nav?.chapter > 0 &&
          ec?.colors?.accent &&
          ec.nav.chapter !== ec.chapters.length
            ? { backgroundColor: ec.colors.accent }
            : null
        }
      >
        {lakota && (
          <PaintStroke
            className={classNames(
              "ease absolute inset-0 h-full w-full transition duration-200",
              {
                "group-hover:fill-red-dark fill-red": color !== "white",
                "fill-white group-hover:opacity-50": color === "white",
              }
            )}
          />
        )}
        {romero && icon && (
          <FlameIcon className="mr-1 h-3 w-3 fill-gold group-hover:fill-white" />
        )}
        <span className="relative z-10 flex text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${classes} ${_className}`}
      onClick={onClick}
      style={
        ec?.chapter && ec?.colors && !color
          ? {
              backgroundColor:
                color === "secondary" ? ec.colors.accent : ec.colors.secondary,
            }
          : null
      }
    >
      {lakota && (
        <PaintStroke
          className={classNames(
            "ease absolute inset-0 h-full w-full transition duration-200",
            {
              "group-hover:fill-red-dark fill-red": color !== "white",
              "fill-white group-hover:opacity-50": color === "white",
            }
          )}
        />
      )}
      {romero && icon && (
        <FlameIcon className="h-3 w-3 text-gold hover:text-white" />
      )}
      <span className="relative z-10 flex text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: brand.romero ? "blue" : "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  outline: false,
  to: null,
  rounded: brand.romero,
};

export default Button;
