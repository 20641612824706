import React, { useEffect, useState, useRef } from "react";

import { Icon, Text, Button } from "@atoms";
import { useAppState } from "@state";
import classNames from "classnames";
import useGivingCampaignData from "@staticQueries/lakotaGc/GivingCampaignQuery";
import useRomeroDefaultsData from "@staticQueries/romero/DefaultsQuery";
import useLakotaDefaultsData from "@staticQueries/lakota/DefaultsQuery";
import useLakotaAcDefaultsData from "@staticQueries/lakotaAc/DefaultsQuery";
import useLgcaDefaultsData from "@staticQueries/lgca/DefaultsQuery";
import useLgcaAcDefaultsData from "@staticQueries/lgcaAc/DefaultsQuery";

import _brand from "@utils/brand";

const { brand, lakota, lgca, romero, site } = _brand;

const Share = ({
  title,
  small,
  cta,
  formid,
  trackingId: _trackingId,
  shareUrlOverride,
  color,
  compact,
  dark,
  light,
  url: _url,
  links,
  body,
  intent,
}) => {
  const [{ ea, layout }, dispatch] = useAppState();
  const { shareData: _shareData } = ea;
  const shareData = _shareData?.shareData?.[formid];
  const copied = useRef();
  const defaults = {
    lakota: useLakotaDefaultsData(),
    romero: useRomeroDefaultsData(),
    lakotaAc: useLakotaAcDefaultsData(),
    lgca: useLgcaDefaultsData(),
    lgcaAc: useLgcaAcDefaultsData(),
    lakotaGc: useGivingCampaignData(),
  }[site];

  const { meta: metaDefaults } = defaults || {};
  const { meta: metaState } = layout;

  const meta = metaState || metaDefaults;

  const copyText = () => {
    navigator.clipboard.writeText(_url || window?.location.href).then(
      () => {
        // eslint-disable-next-line no-console
        console.log("Async: Copying to clipboard was successful!");
      },
      err => {
        // eslint-disable-next-line no-console
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  // setup component states
  const [trackingId, setTrackingId] = useState(
    _trackingId || shareData?.trackingId || false
  );
  // eslint-disable-next-line no-unused-vars
  const [twitterText, setTwitterText] = useState(
    shareData?.twitterText || meta.twitterDescription || "Take action!"
  );
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState(shareUrlOverride || _url);
  const [urls, setUrls] = useState({
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      `${url}${trackingId ? `?ms=tw_share&snw=2&ref=${trackingId}` : ""}`
    )}`,
    Facebook: `https://www.facebook.com/dialog/feed?app_id=${
      process.env.GATSBY_FACEBOOK_APP_ID
    }&link=${encodeURIComponent(
      `${url}${trackingId ? `?ms=fb_share&snw=1&ref=${trackingId}` : ""}`
    )}`,
  });

  useEffect(() => {
    setTwitterText(
      shareData?.twitterText ||
        meta?.twitterDescription ||
        meta?.metaDescription ||
        meta?.title ||
        "Take action!"
    );
  }, [shareData?.twitterText, meta]);

  const subject = meta.title;

  const email = `mailto:?subject=${subject}&body=${twitterText}\n\n${url}`;

  const handleClick = (e, social) => {
    if (_trackingId || trackingId) {
      // e.preventDefault();
      fetch(`https://actions.everyaction.com/v1/RecruiterActivity/${formid}`, {
        method: "POST",
        headers: {
          SocialNetwork: social,
          SubmissionTrackingId: _trackingId || trackingId,
        },
      })
        .then(() => {
          return true;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          return true;
        });
    }
  };

  // when tracking id changes, load new trackingid + twitter text
  useEffect(() => {
    const timeout = setTimeout(() => {
      // get new data if any
      const twitterShare = document.getElementById("twShareBtn");

      // if there is already data from the app state, use it
      if (twitterText && trackingId) {
        // set tracking id, twitter text, and url from state
        setUrls({
          Email: email,
          Twitter: `https://twitter.com/intent/tweet?text=${twitterText}&url=${encodeURIComponent(
            `${url}?ms=tw_share&snw=2&ref=${trackingId}`
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?app_id=${
            process.env.GATSBY_FACEBOOK_APP_ID
          }&link=${encodeURIComponent(
            `${url}?ms=fb_share&snw=1&ref=${trackingId}`
          )}`,
        });
        return;
      }

      // if there is new data
      if (twitterShare && _trackingId) {
        // get + set twitter text, tracking id
        const _twitterText = twitterShare.getAttribute("data-text");
        if (_twitterText) {
          setTwitterText(_twitterText);
        }
        setTrackingId(_trackingId);
        dispatch({
          type: "addShareData",
          formid,
          data: {
            twitterText: _twitterText,
            trackingId: _trackingId,
          },
        });
        setUrls({
          Email: email,
          Twitter: `https://twitter.com/intent/tweet?text=${_twitterText}&url=${encodeURIComponent(
            `${url}?ms=tw_share&snw=2&ref=${_trackingId}`
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?app_id=${
            process.env.GATSBY_FACEBOOK_APP_ID
          }&link=${encodeURIComponent(
            `${url}?ms=fb_share&snw=1&ref=${_trackingId}`
          )}`,
        });
      }
      // else just share the url with default text
      else {
        // just share the urls with default text if no text or tracking id to share
        setUrls({
          Email: email,
          Twitter: `https://twitter.com/intent/tweet?text=${twitterText}&url=${encodeURIComponent(
            url
          )}`,
          Facebook: `https://www.facebook.com/dialog/feed?app_id=${
            process.env.GATSBY_FACEBOOK_APP_ID
          }&link=${encodeURIComponent(url)}`,
        });
      }
    }, 250);
    // cleanup
    return () => {
      clearTimeout(timeout);
    };
  }, [_trackingId, trackingId, twitterText, meta]);

  return (
    <div className="flex items-center">
      {cta !== false && (
        <Text
          variant={`${small ? "body" : "body--large-tight"}`}
          className={classNames("pr-2", {
            "text-white": lgca && light,
            "text-black": lgca && !light,
            "font-serif text-red": lakota && !light,
            "font-serif text-white": lakota && light,
            "font-bold": !romero,
            "font-medium": romero,
            "font-extended": lgca,
          })}
        >
          {cta || "Share"}
        </Text>
      )}
      <div className="flex px-2">
        <Button
          size="xxs"
          to={urls.Twitter}
          target="_blank"
          color={color}
          onClick={e => handleClick(e, "Twitter")}
          rounded={!lakota}
        >
          <Icon name="twitter" className="h-4 w-4 p-px" fitHeight />
        </Button>
      </div>
      <div className="flex px-2">
        <Button
          size="xxs"
          to={urls.Facebook}
          target="_blank"
          color={color}
          onClick={e => handleClick(e, "Facebook")}
          rounded={!lakota}
        >
          <Icon name="facebook" className="h-4 w-4" fitHeight />
        </Button>
      </div>
      {links && (
        <div className="flex px-2">
          <Button
            size="xxs"
            to={urls.Email}
            target="_blank"
            color={color}
            rounded={!lakota}
          >
            <Icon
              name="email"
              className={classNames({
                "h-3 w-3": small && romero,
                "h-4 w-4 p-px": small && !romero,
                "h-6 w-6 p-1": !small,
              })}
              fitHeight
            />
          </Button>
        </div>
      )}
      {links && (
        <div className="relative flex px-2">
          <div
            ref={copied}
            className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center whitespace-nowrap text-center text-xxs font-bold uppercase text-white opacity-0"
          >
            <div className="bg-black p-1">Link Copied!</div>
          </div>
          <Button
            size="xxs"
            target="_blank"
            color={color}
            rounded={!lakota}
            onClick={copyText}
          >
            <Icon
              name="link"
              className={classNames({
                "h-3 w-3": small && romero,
                "h-4 w-4 p-px": small && !romero,
                "h-6 w-6 p-1": !small,
              })}
              fitHeight
            />
          </Button>
        </div>
      )}
    </div>
  );
};

const colors = {
  romero: "blue",
  lakota: "red",
  lgca: "orange",
};

Share.defaultProps = {
  color: colors[brand],
  url: process.env.GATSBY_SITE_URL,
  intent: "homepage",
};

export default Share;
