import React, { useReducer, useEffect } from "react";

import brand from "@utils/brand";
import { AnimateIn } from "@atoms";
import { m } from "framer-motion";
import loadable from "@loadable/component";
import { useAppState } from "@state";
import actionReducer from "@reducers/action";

const ImpactGrid = loadable(() => import("@organisms/ImpactGrid"));
const Action = loadable(() => import("@templates/Action"));
const SocialFeed = loadable(() => import("@organisms/SocialFeed"));
const CardExpandedGrid = loadable(() => import("@organisms/CardExpandedGrid"));

const { romero } = brand;

const ActionPage = ({ data, allActions }) => {
  // the data
  const {
    activistCodesExclusive,
    issue: _issue,
    showImpactGrid: _showImpactGrid,
    showSocialFeed: _showSocialFeed,
    showRelatedContent: _showRelatedContent,
    impact: _impactGrid,
    social: _social,
    relatedContent: _relatedContent,
    layout,
    levels,
    slug,
  } = data;

  // the state + template settings
  const [{ ea }, dispatch] = useAppState();
  const hasCodes = ea && ea.activistCodes;

  useEffect(() => {
    dispatch({ type: "setThemeDark" });
  }, []);

  // determine if the user has completed this action before
  const getStatus = () => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoSubmitSuppressed = urlParams.get("autoSubmitSuppressed");
      const reset = urlParams.get("reset");
      if (autoSubmitSuppressed === "true") {
        return "unsubmitted";
      }
      if (reset === "true") {
        return "reset";
      }
    }
    if (
      hasCodes &&
      activistCodesExclusive &&
      activistCodesExclusive.find(code => ea.activistCodes.includes(code))
    ) {
      return "completed";
    }
    return "unsubmitted";
  };

  const [actionState, actionDispatch] = useReducer(actionReducer, {
    trackingId: null,
    status: getStatus(),
    levels,
    amount: null,
    // todo: get initial level from cms
    level: levels ? 0 : null,
    // todo: get total using transact fee
    total: null,
    // todo: make this editable in the CMS
    // eslint-disable-next-line no-nested-ternary
    frequency: levels?.length ? (romero ? "annual" : "monthly") : "once",
    visibleAmounts: levels
      ? levels?.[1]?.[romero ? "annual" : "monthly"]
      : null,
    amountIdx: 0,
  });

  // get social and impact grids
  const issue = {
    impactGrid: _issue ? _issue.impactGrid : {},
    social: _issue ? _issue.social : {},
    relatedContent: _issue ? _issue.relatedContent : {},
  };
  const impactGrid = _impactGrid.length > 0 ? _impactGrid : issue.impactGrid;
  const social = _social.embedCodes?.length > 0 ? _social : issue.social;
  const relatedContent =
    _relatedContent.cards?.length > 0 ? _relatedContent : issue.relatedContent;
  const showImpactGrid = _showImpactGrid && impactGrid && impactGrid.length > 0;
  const showSocialFeed =
    _showSocialFeed &&
    social &&
    social.embedCodes &&
    social.embedCodes.length > 0;
  const showRelatedContent =
    _showRelatedContent &&
    relatedContent &&
    relatedContent.cards &&
    relatedContent.cards?.filter(c => c.title)?.length > 0;

  const actionVariants = {
    submitted: { height: 0 },
    reset: { height: "auto" },
    completed: { height: 0 },
  };
  const thankYouVariants = {
    submitted: { height: "auto" },
    reset: { height: 0 },
    completed: { height: "auto" },
  };

  return (
    <>
      <section className="flex flex-grow">
        <Action
          allActions={allActions}
          {...data}
          hasCodes={hasCodes}
          actionState={actionState}
          actionDispatch={actionDispatch}
          path={`/action/${slug}`}
          ea={ea}
          layout={layout}
          thankYouFade={null}
          dispatch={dispatch}
          levels={levels}
        />
      </section>
      {/* Impact */}
      <m.section
        intial="visible"
        animate={actionState.status}
        variants={actionVariants}
        className="overflow-hidden"
      >
        {showImpactGrid && impactGrid && (
          <div className="bg-cream">
            <AnimateIn preset="fadeInFast">
              <ImpactGrid {...impactGrid} icon="sunrise" />
            </AnimateIn>
          </div>
        )}
      </m.section>
      <m.section
        intial="hidden"
        animate={actionState.status}
        variants={thankYouVariants}
        className="h-0 overflow-hidden"
      >
        <div>
          {/* Social */}
          {showSocialFeed &&
            social &&
            social.embedCodes &&
            social.embedCodes.length > 0 && (
              <div className="bg-cream">
                <AnimateIn>
                  <div>
                    <SocialFeed
                      {...social}
                      heading={_social.heading || social.heading}
                      icon="sunrise"
                    />
                  </div>
                </AnimateIn>
              </div>
            )}
        </div>
        <div>
          {/* Related Content */}
          {showRelatedContent && (
            <div className="bg-cream">
              <AnimateIn>
                <div>
                  <CardExpandedGrid {...relatedContent} icon="sunrise" />
                </div>
              </AnimateIn>
            </div>
          )}
        </div>
      </m.section>
    </>
  );
};

ActionPage.defaultProps = {};

export default ActionPage;
