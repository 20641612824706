import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveCB from "@dataResolvers/resolveCB";

const query = graphql`
  query GivingCampaignQuery {
    craft {
      entry(section: "homeLakotaGc", site: "lakotaGc") {
        ... on Craft_homeLakotaGc_homeLakotaGc_Entry {
          givingCampaign {
            ... on Craft_givingCampaignLakota_givingCampaignLakota_Entry {
              metaTitle
              metaDescription
              metaImage {
                id
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              slug
              title
              # p2p
              ctaHeading: heading4
              ctaCopy: copy2
              ctaButton: link2 {
                ... on Craft_link2_linkObject_BlockType {
                  enabled
                  linkLink {
                    url
                  }
                  linkText
                }
              }
              donateFormId: formid
              membershipFormId: alternateActionCta
              coverImageOptions: membershipHeroImages {
                id
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              actionDescription: copy3
              # hero
              goal
              heroBackground: backgroundImage0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              heroHeading: heading0
              heroCopy: copy0
              heroButton: link0 {
                ... on Craft_link0_linkObject_BlockType {
                  enabled
                  linkLink {
                    url
                  }
                  linkText
                }
              }
              heroAltLinks: linkList {
                ... on Craft_linkList_listLink_BlockType {
                  uid
                  enabled
                  listLink {
                    url
                  }
                  linkText
                }
              }
              # video
              placeholderVideo: videoUrl
              embedCode
              twitchChannel: downloadText
              live: boolean0
              # detal
              detailLabel: heading1
              detailHeading: heading2
              detailSubheading: heading3
              detailCopy: copy1
              detailButton: link1 {
                ... on Craft_link1_linkObject_BlockType {
                  enabled
                  linkLink {
                    url
                  }
                  linkText
                }
              }

              # midpage cta
              midPageCtaHeading: heading5
              midPageCtaCopy: gdprOptInCopy
              midPageCtaButton: link3 {
                ... on Craft_link3_linkObject_BlockType {
                  enabled
                  linkLink {
                    url
                  }
                  linkText
                }
              }
              midPageCtaAltLink: headerButton {
                text
                url
              }
              midPageCtaBackgroundImage: backgroundImage2 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              # history
              missionStatement: copy4
              historyHeading: heading6
              historyCarousel {
                ... on Craft_historyCarousel_card_BlockType {
                  heading
                  description
                  image {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
              }
              pullQuoteImage: backgroundImage1 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              pullQuoteCopy: navCopy
              # social feed
              socialFeed {
                ... on Craft_socialFeed_instagram_BlockType {
                  enabled
                  code
                }
                ... on Craft_socialFeed_facebookPost_BlockType {
                  enabled
                  code
                }
                ... on Craft_socialFeed_tweet_BlockType {
                  enabled
                  code
                }
              }
              # meta
              metaTitle
              metaDescription
              metaImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              contentBuilder {
                ... on Craft_contentBuilder_heading_BlockType {
                  enabled
                  id
                  typeHandle
                  heading
                }
                ... on Craft_contentBuilder_subheading_BlockType {
                  enabled
                  id
                  typeHandle
                  subheading
                  enabled
                }
                ... on Craft_contentBuilder_leadText_BlockType {
                  enabled
                  id
                  typeHandle
                  leadText
                }
                ... on Craft_contentBuilder_copy_BlockType {
                  enabled
                  id
                  typeHandle
                  copy
                  indent
                }
                ... on Craft_contentBuilder_list_BlockType {
                  enabled
                  id
                  typeHandle
                  listItem
                  enabled
                  restartCount
                }
                ... on Craft_contentBuilder_image_BlockType {
                  enabled
                  id
                  typeHandle
                  enabled
                  indent
                  images {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
                ... on Craft_contentBuilder_video_BlockType {
                  enabled
                  id
                  typeHandle
                  indent
                  videoUrl
                  caption
                }
                ... on Craft_contentBuilder_link_BlockType {
                  enabled
                  id
                  typeHandle
                  linkLink {
                    url
                  }
                  linkText
                  indent
                  removeTopMargin
                }
                ... on Craft_contentBuilder_blockQuote_BlockType {
                  enabled
                  id
                  typeHandle
                  quote
                  citation
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const { givingCampaign } = entry;
  const {
    slug,
    donateFormId,
    membershipFormId,
    ctaHeading,
    ctaCopy,
    ctaButton,
    coverImageOptions,
    actionDescription,
    heroBackground,
    heroHeading,
    heroCopy,
    heroButton,
    heroAltLinks,
    detailLabel,
    detailHeading,
    detailSubheading,
    detailCopy,
    detailButton,
    placeholderVideo,
    embedCode,
    midPageCtaHeading,
    midPageCtaCopy,
    midPageCtaButton,
    midPageCtaAltLink,
    midPageCtaBackgroundImage,
    missionStatement,
    historyHeading,
    historyCarousel,
    pullQuoteImage,
    pullQuoteCopy,
    socialFeed,
    metaTitle,
    metaDescription,
    metaImage,
    contentBuilder,
    twitchChannel,
    live,
    goal,
  } = givingCampaign[0];
  return {
    slug,
    url: process.env.GATSBY_SITE_URL,
    meta: {
      title: heroHeading,
      metaDescription,
      metaImage: resolveImage(metaImage),
      metaTitle,
      slug,
      url: process.env.GATSBY_SITE_URL,
    },
    hero: {
      image: resolveImage(heroBackground),
    },
    stream: {
      heading: heroHeading,
      copy: heroCopy,
      button: {
        text: heroButton[0]?.linkText,
        url: heroButton[0]?.linkLink?.url,
      },
      links: heroAltLinks.map(item => {
        return {
          url: item?.listLink?.url,
          text: item?.linkText,
        };
      }),
      placeholderVideo,
      embedCode,
      twitchChannel,
      live,
      url: process.env.GATSBY_SITE_URL,
      widget: {
        channel: "909898570763735080",
        server: "909898518548865074",
      },
    },
    rsvp: {
      label: detailLabel,
      heading: detailHeading,
      subheading: detailSubheading,
      copy: detailCopy,
      link: {
        text: detailButton[0].linkText,
        url: detailButton[0].linkLink?.url,
      },
    },
    fundraiser: {
      heading: ctaHeading,
      copy: ctaCopy,
      link: {
        text: ctaButton[0].linkText,
        url: ctaButton[0].linkLink?.url,
      },
    },
    cta: {
      heading: midPageCtaHeading,
      copy: midPageCtaCopy,
      action: {
        url: midPageCtaButton.url,
        cta: midPageCtaButton.text,
      },
      altLink: {
        url: midPageCtaAltLink.url,
        text: midPageCtaAltLink.text,
      },
      backgroundImage: resolveImage(midPageCtaBackgroundImage),
    },
    history: {
      mission: missionStatement,
      heading: historyHeading,
      contentBuilder: resolveCB(contentBuilder),
      cards: historyCarousel.map(card => {
        return {
          heading: card.heading,
          copy: card.description,
          image: resolveImage(card.image),
        };
      }),
      pullQuote: {
        image: resolveImage(pullQuoteImage),
        // heading: pullQuote1[0].heading,
        quote: pullQuoteCopy,
      },
    },
    campaign: {
      goal,
      donateFormId,
      membershipFormId,
    },
    p2p: {
      description: actionDescription,
      coverImageOptions,
    },
    social: {
      embedCodes: socialFeed.map(code => code.code),
    },
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  return dataResolver(craft);
};

export default useData;
